.tooltips {
  position: relative;
  display: inline-flex;

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    cursor: pointer;
    outline: none;

    background-color: transparent;
    border: none;

    .icon {
      fill: var(--tooltips-icon-fill);

      transition: all var(--transition) ease 0s;
    }

    &:hover {
      .icon {
        fill: var(--tooltips-icon-fill-hover);
      }
    }
  }
  &__content {
    position: absolute;
    visibility: hidden;
    z-index: 30;
    opacity: 0;

    transition: all var(--transition) ease 0s;

    &--top {
      bottom: var(--tooltips-content-position);
      left: 50%;

      transform: translateX(-50%);
    }
    &--bottom {
      top: var(--tooltips-content-position);
      left: 50%;

      transform: translateX(-50%);
    }
    &--left {
      top: 50%;
      right: var(--tooltips-content-position);

      transform: translateY(-50%);
    }
    &--right {
      top: 50%;
      left: var(--tooltips-content-position);

      transform: translateY(-50%);
    }
  }
  &__content--open {
    visibility: visible;
    opacity: 1;
  }
}
