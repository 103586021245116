@import "../../assets/sass/mixins.scss";
.passports {
  &__list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-l);
    column-gap: 15px;
    align-items: center;

    & > * {
      align-self: normal;
    }

    @include screen(xl) {
      row-gap: var(--space-xl);
    }
  }
  &__button-create {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

    @include screen(md) {
      max-width: 434px;
    }
    @include screen(xl) {
      max-width: 544px;
    }
    &:not(:first-child) {
      margin-top: var(--space-m);
    }
  }
}
