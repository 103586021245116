@import "../../../assets/sass/mixins.scss";
.vertical-offset {
  display: flex;
  flex-direction: column;
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .vertical-offset-#{$infix}-4xs {
      row-gap: var(--space-4xs);
    }
    .vertical-offset-#{$infix}-3xs {
      row-gap: var(--space-3xs);
    }
    .vertical-offset-#{$infix}-2xs {
      row-gap: var(--space-2xs);
    }
    .vertical-offset-#{$infix}-xs {
      row-gap: var(--space-xs);
    }
    .vertical-offset-#{$infix}-2s {
      row-gap: var(--space-2s);
    }
    .vertical-offset-#{$infix}-1s {
      row-gap: var(--space-1s);
    }
    .vertical-offset-#{$infix}-s {
      row-gap: var(--space-s);
    }
    .vertical-offset-#{$infix}-m {
      row-gap: var(--space-m);
    }
    .vertical-offset-#{$infix}-l {
      row-gap: var(--space-l);
    }
    .vertical-offset-#{$infix}-2l {
      row-gap: var(--space-2l);
    }
    .vertical-offset-#{$infix}-xl {
      row-gap: var(--space-xl);
    }
    .vertical-offset-#{$infix}-2xl {
      row-gap: var(--space-2xl);
    }
    .vertical-offset-#{$infix}-3xl {
      row-gap: var(--space-3xl);
    }
    .vertical-offset-#{$infix}-4xl {
      row-gap: var(--space-4xl);
    }
    .vertical-offset-#{$infix}-5xl {
      row-gap: var(--space-5xl);
    }
    .vertical-offset-#{$infix}-6xl {
      row-gap: var(--space-6xl);
    }
    .vertical-offset-#{$infix}-xxl {
      row-gap: var(--space-xxl);
    }
    .vertical-offset-#{$infix}-2xxl {
      row-gap: var(--space-2xxl);
    }
    .vertical-offset-#{$infix}-3xxl {
      row-gap: var(--space-3xxl);
    }
    .vertical-offset-#{$infix}-4xxl {
      row-gap: var(--space-4xxl);
    }
  }
}
