@import "../../assets/sass/mixins.scss";
.footer {
  position: relative;

  margin-bottom: 68px;
  @include screen(md) {
    margin-bottom: 98px;
  }
  @include screen(xl) {
    margin-bottom: var(--space-2xl);
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: var(--space-2xl);

    @include screen(md) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  &__desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 14px;
    width: 100%;
    max-width: 210px;

    text-align: center;

    @include screen(md) {
      align-items: flex-start;
      max-width: 214px;
      row-gap: 6px;

      text-align: left;
    }
    @include screen(xl) {
      a.logo {
        margin-bottom: var(--space-1s);
      }
    }
  }
  &__nav {
    display: flex;
    flex-direction: column;
    align-self: normal;
    row-gap: var(--space-2xl);

    @include screen(md) {
      flex: 0 1 auto;
      box-sizing: border-box;
      flex-direction: row;
      column-gap: var(--space-3xl);
    }
    @include screen(xl) {
      justify-content: space-between;
    }
  }
  &__menu {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-1s);
    text-align: center;

    @include screen(md) {
      text-align: left;
    }
  }
  &__menu-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: normal;
    row-gap: var(--space-1s);

    text-align: center;

    @include screen(md) {
      align-items: flex-start;
      text-align: left;
    }
    @include screen(xl) {
      width: 162px;
    }

    .link {
      color: var(--color-footer-url);

      &:hover {
        color: var(--link-color-hover);
      }
    }
  }
  &__menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: normal;
    row-gap: var(--space-1s);

    @include screen(md) {
      align-items: flex-start;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-2xl);

    @include screen(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex: 1 1 auto;
    }
    @include screen(xl) {
      flex: 0 1 auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: var(--space-xl);
    }
  }
  &__social {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    padding-top: var(--space-2l);
    padding-bottom: var(--space-2l);

    @include screen(md) {
      align-items: flex-start;
      row-gap: var(--space-1s);
      padding-top: 0;
      padding-bottom: 0;
    }

    .menu-social {
      width: 100%;
      max-width: 220px;
      justify-content: space-between;
      flex-wrap: wrap;
      column-gap: var(--space-2xl);
      row-gap: var(--space-l);

      @include screen(md) {
        justify-content: flex-start;
        max-width: 100%;
        column-gap: var(--space-2s);
        row-gap: var(--space-m);
      }
      @include screen(xl) {
        max-width: 286px;
      }
    }
    .menu-social__link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 46px;

      border-radius: 50%;
      background-color: var(--color-bg-icon);

      transition: all var(--transition) ease 0s;

      @include screen(md) {
        width: 36px;
        height: 36px;
      }

      &:hover {
        background-color: var(--color-primary-purple);
      }
      &:hover .icon {
        transform: scale(1);
      }
      &:hover .icon {
        transform: scale(1);
      }
      &:hover .icon-img {
        transform: scale(1);
      }
      .icon-img--size-ex-small {
        @include screen(mobile) {
          width: 26px;
          height: 26px;
        }
      }
    }
  }
  &--page {
    padding-top: var(--space-6xl);
    padding-bottom: var(--space-3xl);
    margin-bottom: 0;

    @include screen(md) {
      padding-top: 98px;
      padding-bottom: 98px;
    }

    @include screen(xl) {
      padding-top: 95px;
      padding-bottom: 95px;
    }
  }
}
.footer__text {
  max-width: 600px;
  padding-top: 50px;
  text-align: center;
  margin: 0 auto;
}
