@import "../../../../assets/sass/mixins.scss";
/* Size */
.text--size-h1 {
  font-family: var(--font-main);
  font-size: var(--font-size-36);
  line-height: var(--font-line-height-48);
  font-weight: 800;

  @include screen(md) {
    font-size: var(--font-size-50);
    line-height: var(--font-line-height-60);
  }

  @include screen(xl) {
    font-size: var(--font-size-72);
    line-height: var(--font-line-height-82);
  }
}
.text--size-h2 {
  font-family: var(--font-main);
  font-size: var(--font-size-30);
  line-height: var(--font-line-height-38);
  font-weight: 800;

  @include screen(md) {
    font-size: var(--font-size-32);
    line-height: var(--font-line-height-38);
  }

  @include screen(xl) {
    font-size: var(--font-size-48);
    line-height: var(--font-line-height-62);
  }
}
.text--size-h3 {
  font-family: var(--font-main);
  font-size: var(--font-size-20);
  line-height: var(--font-line-height-26);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-24);
    line-height: var(--font-line-height-32);
  }

  @include screen(xl) {
    font-size: var(--font-size-38);
    line-height: var(--font-line-height-44);
  }
}
.text--size-h4 {
  font-family: var(--font-main);
  font-size: var(--font-size-18);
  line-height: var(--font-line-height-24);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-22);
    line-height: var(--font-line-height-30);
  }

  @include screen(xl) {
    font-size: var(--font-size-28);
    line-height: var(--font-line-height-34);
  }
}
.text--size-h5 {
  font-family: var(--font-main);
  font-size: var(--font-size-16);
  line-height: var(--font-line-height-24);
  font-weight: 400;

  @include screen(md) {
    font-size: var(--font-size-18);
    line-height: var(--font-line-height-24);
  }

  @include screen(xl) {
    font-size: var(--font-size-24);
    line-height: var(--font-line-height-30);
  }
}
.text--size-h5-bold {
  font-family: var(--font-main);
  font-size: var(--font-size-16);
  line-height: var(--font-line-height-24);
  font-weight: bold;

  @include screen(md) {
    font-size: var(--font-size-18);
    line-height: var(--font-line-height-24);
  }

  @include screen(xl) {
    font-size: var(--font-size-24);
    line-height: var(--font-line-height-30);
  }
}
.text--size-button {
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-18);
  font-weight: 500;

  @include screen(md) {
    font-size: var(--font-size-16);
    line-height: var(--font-line-height-20);
  }

  @include screen(xl) {
    font-size: var(--font-size-18);
    line-height: var(--font-line-height-24);
  }
}
.text--size-counter {
  font-family: var(--font-main);
  font-size: var(--font-size-24);
  line-height: var(--font-line-height-28);
  font-weight: normal;

  @include screen(md) {
    font-size: var(--font-size-40);
    line-height: var(--font-line-height-58);
  }

  @include screen(xl) {
    font-size: var(--font-size-64);
    line-height: var(--font-line-height-86);
  }
}
.text--size-body-small {
  font-family: var(--font-main);
  font-size: var(--font-size-13);
  line-height: var(--font-line-height-18);
  font-weight: normal;

  @include screen(md) {
    font-size: var(--font-size-14);
  }
  @include screen(xl) {
    font-size: var(--font-size-16);
    line-height: var(--font-line-height-20);
  }
}
.text--size-body-bold-small {
  font-family: var(--font-main);
  font-size: var(--font-size-13);
  line-height: var(--font-line-height-18);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-14);
    line-height: var(--font-line-height-20);
  }

  @include screen(xl) {
    font-size: var(--font-size-16);
  }
}

.text--size-body-large {
  font-family: var(--font-main);
  font-size: var(--font-size-16);
  line-height: var(--font-line-height-24);
  font-weight: normal;

  @include screen(md) {
    font-size: var(--font-size-18);
  }

  @include screen(xl) {
    font-size: var(--font-size-24);
    line-height: var(--font-line-height-30);
  }
}
.text--size-body-bold-large {
  font-family: var(--font-main);
  font-size: var(--font-size-16);
  line-height: var(--font-line-height-24);
  font-weight: bold;

  @include screen(md) {
    font-size: var(--font-size-18);
  }

  @include screen(xl) {
    font-size: var(--font-size-24);
    line-height: var(--font-line-height-30);
  }
}
.text--size-body-medium {
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-22);
  font-weight: 400;

  @include screen(md) {
    font-size: var(--font-size-16);
    line-height: var(--font-line-height-24);
  }

  @include screen(xl) {
    font-size: var(--font-size-18);
    line-height: var(--font-line-height-26);
  }
}
.text--size-body-bold-medium {
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-18);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-16);
    line-height: var(--font-line-height-20);
  }

  @include screen(xl) {
    font-size: var(--font-size-18);
    line-height: var(--font-line-height-22);
  }
}
.text--size-urls-menu {
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-20);
  font-weight: 500;
}
.text--size-urls-menu-secendary {
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-22);
  font-weight: normal;
}
.text--size-urls-menu-medium {
  font-family: var(--font-main);
  font-size: var(--font-size-18);
  line-height: var(--font-line-height-20);
  font-weight: 500;
}

.text--size-mobile-body-regular {
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-22);
  font-weight: normal;
}
.text--size-mobile-body-bold {
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-18);
  font-weight: bold;
}
.text--size-caption {
  font-family: var(--font-main);
  font-size: var(--font-size-13);
  line-height: var(--font-line-height-18);
  font-weight: normal;
}
.text--size-body-xsmall {
  font-family: var(--font-main);
  font-size: var(--font-size-12);
  line-height: var(--font-line-height-18);
  font-weight: 400;

  @include screen(md) {
    font-size: var(--font-size-13);
  }

  @include screen(xl) {
    font-size: var(--font-size-14);
    line-height: var(--font-line-height-20);
  }
}
.text--size-body-button-tablet {
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-18);
  font-weight: 500;

  @include screen(md) {
    font-size: var(--font-size-16);
    line-height: var(--font-line-height-20);
  }
}
