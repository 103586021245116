.btn--btn-icon {
  min-height: var(--button-btn-icon-height);
  padding: var(--button-btn-icon-padding);

  @include screen(md) {
    min-height: var(--button-btn-icon-md-height);
    padding: var(--button-btn-icon-md-padding);
  }
  @include screen(lg) {
    min-height: var(--button-btn-icon-lg-height);
    padding: var(--button-btn-icon-lg-padding);
  }
}
