.icon-text {
  display: inline-flex;
  align-items: center;

  &--gap-esm {
    gap: 2px;
  }
  &--gap-sm {
    gap: 4px;
  }
  &--gap-m {
    gap: 6px;
  }
  &--gap-lg {
    gap: 8px;
  }
  &--gap-xl {
    gap: 10px;
  }
}
