@import "../../../assets/sass/mixins.scss";
.select-custom {
  position: relative;

  width: 100%;

  &__label {
    display: flex;
    min-height: var(--input-label-height);
    box-sizing: border-box;
    padding-bottom: 8px;
  }
  &__control {
    align-items: center;
    box-sizing: border-box;
    padding: var(--select-custom-control-pd);
    height: var(--select-custom-control-height) !important;

    cursor: pointer !important;
    border-radius: var(--select-custom-border-radius) !important;
    border-color: var(--select-custom-border-color) !important;
    background-color: var(--select-custom-bg-color) !important;
    box-shadow: none !important;

    transition: all var(--transition) ease 0s;

    @include screen(xl) {
      height: var(--select-custom-control-height-xl);
    }

    &:hover {
      border-color: var(--color-primary-purple) !important;
      box-shadow: var(--select-custom-box-shadow) !important;

      @include screen(md) {
        box-shadow: var(--select-custom-box-shadow-md) !important;
      }
      @include screen(xl) {
        box-shadow: var(--select-custom-box-shadow-xl) !important;
      }
    }
  }
  &__input-container {
    padding: 0 !important;
    margin: 0 !important;

    color: var(--select-custom-text-color) !important;
    border: none !important;
  }
  &__value-container {
    padding: 0 !important;

    font-size: var(--select-custom-font-size);
    line-height: var(--select-custom-line-height);
    color: var(--select-custom-text-color) !important;

    @include screen(xl) {
      font-size: var(--select-custom-font-size-xl);
      line-height: var(--select-custom-line-height-xl);
    }
  }
  &__single-value {
    display: flex;
    margin: 0 !important;

    color: var(--select-custom-text-color) !important;
  }
  &__indicator {
    padding: 0 0 0 var(--select-custom-indicator-pd) !important;

    cursor: pointer !important;

    .icon {
      transition: all var(--transition) ease 0s;

      // &:hover {
      //   fill: var(--select-custom-indicator-icon-fill-hover);
      // }
    }
  }
  &__indicator-separator {
    display: none;
  }
  &__option--is-selected {
    background-color: transparent !important;

    // &::before {
    //   position: absolute;
    //   top: 48%;
    //   right: 12px;

    //   display: flex;
    //   width: var(--langs-option-check-size);
    //   height: var(--langs-option-check-size);

    //   content: "";
    //   background-image: url(../../../assets/icon/icon--check-grey.svg);
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   background-position: center;
    //   transform: translateY(-50%);
    // }
  }
  &__control--menu-is-open {
    border-radius: var(--select-custom-border-radius) !important;
    border-color: var(--select-custom-border-color-active) !important;

    background-color: var(--select-custom-bg-color) !important;
  }
  &__option--is-focused {
    background-color: transparent !important;
  }
  &__menu {
    margin: 0 !important;
    margin-top: 8px !important;

    background-color: var(--select-custom-menu-bg-color) !important;
    border: var(--select-custom-border-width) solid var(--select-custom-menu-border-color) !important;
    border-radius: var(--select-custom-border-radius) !important;
    box-shadow: none !important;
  }
  &__menu-list {
    min-height: var(--select-custom-menu-list-min-height);
    max-height: var(--select-custom-menu-list-max-height) !important;
    padding: 0 !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  &__option {
    position: relative;

    display: flex !important;
    padding: var(--select-custom-option-pd) !important;

    font-size: var(--select-custom-font-size) !important;
    line-height: var(--select-custom-line-height) !important;
    color: var(--select-custom-text-color) !important;

    cursor: pointer !important;
    border-radius: 0 !important;

    transition: all var(--transition) ease 0s;

    &:hover {
      background-color: var(--select-custom-option-bg-color-hover) !important;
    }
  }
}
