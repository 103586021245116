/* Color */
.text--color-primary {
  color: var(--color-primary-purple);
}
.text--color-secondary {
  color: var(--color-gray-secendary-text);
}
.text--color-three {
  color: var(--color-footer-url);
}
.text--color-white {
  color: var(--color-white);
}
.text--color-block-stroke {
  color: var(--color-text-block-stroke);
}
.text--color-error {
  color: var(--color-error);
}
.text--color-success {
  color: var(--color-successful);
}
