.modal-success {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-m);

  &__img {
    display: flex;
    width: 100%;
    max-width: 83px;
    margin: 0 auto;
  }
}
