.modal-create-passport {
  &__desc {
    margin-bottom: var(--space-2xs);
  }
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-s);
  }
  &__bottom > .text {
    margin-bottom: var(--space-xs);
  }

  &__fee {

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 10px;

    .btn--icon {
      column-gap: var(--space-4xs);
    }

    &--key {
      flex: 1;
    }

    &--value {
      display: flex;
      align-items: center;
      flex: 2;
      justify-content: flex-end;
      flex-direction: row;
    }
  }
}
