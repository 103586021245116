@import "../../assets/sass/mixins.scss";
.logo {
  display: inline-flex;
  width: 100%;
  max-width: var(--logo-width-mob);

  transition: all var(--transition) ease 0s;

  @include screen(md) {
    max-width: var(--logo-width);
  }

  &:hover {
    opacity: 0.4;
  }
  img {
    display: block;
    width: 100%;
  }

  &--footer {
    max-width: var(--logo-width);
  }
}
