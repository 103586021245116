@import "./fonts.scss";
@import "./variables.scss";
@import "./mixins.scss";

/* Clear */
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  border: 0;
}
a {
  text-decoration: none;
}
ul,
ol,
li {
  padding: 0;
  margin: 0;

  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}
html,
body {
  height: 100%;
  line-height: 1;

  @extend .scrollbar;
}
body {
  font-family: var(--font-main);
  font-size: var(--font-size-body);
  line-height: var(--font-line-height-body);
  color: var(--color-white);

  background-color: var(--color-body-bg);
}
.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100%;
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary-purple-dark) var(--color-primary-purple-light);

  &::-webkit-scrollbar {
    width: 6px;
    height: 80%;
  }
  &::-webkit-scrollbar-track {
    background: var(--color-primary-purple-light);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--color-primary-purple-dark);
    border: 1px solid var(--color-primary-purple-dark);
  }
}

.scrollbar-horizontal {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary-purple-dark) var(--color-primary-purple-light);

  &::-webkit-scrollbar {
    width: 100%;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--color-primary-purple-light);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--color-primary-purple-dark);
    border: 1px solid var(--color-primary-purple-dark);
  }
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  clip-path: inset(100%);
}
