@import "../../../assets/sass/mixins.scss";
.input {
  width: 100%;

  &__label {
    display: flex;
    align-items: center;
    min-height: var(--input-label-height);
    column-gap: var(--input-label-column-gap);
    box-sizing: border-box;
    padding-bottom: 8px;
  }
  &__input {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 5px 14px;
    height: var(--input-input-height);
    box-sizing: border-box;

    font-family: var(--input-font-family);
    font-size: var(--input-font-size);
    line-height: var(--input-line-height);
    color: var(--input-font-color);

    border: var(--input-border-width) solid var(--input-border-color);
    border-radius: var(--input-border-radius);
    background-color: var(--input-bg-color);
    cursor: pointer;
    outline: none;

    transition: all 0.4s;

    @include screen(xl) {
      height: var(--input-input-height-xl);

      font-size: var(--input-font-size-xl);
      line-height: var(--input-line-height-xl);
    }

    &::-webkit-input-placeholder {
      color: var(--input-placeholder-color);
    }
    &::-moz-placeholder {
      color: var(--input-placeholder-color);
      text-overflow: ellipsis;

      opacity: 1;
    }
    &:-moz-placeholder {
      color: var(--input-placeholder-color);
      text-overflow: ellipsis;

      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: var(--input-placeholder-color);
      text-overflow: ellipsis;
    }
    &[placeholder] {
      text-overflow: ellipsis;
    }
    &:hover,
    &:active,
    &:focus {
      border-color: var(--input-border-color-hover);
      box-shadow: var(--input-box-shadow-hover);

      @include screen(md) {
        box-shadow: var(--input-box-shadow-hover-md);
      }
      @include screen(xl) {
        box-shadow: var(--input-box-shadow-hover-xl);
      }
    }

    &:disabled {
      color: var(--input-color-disabled);

      cursor: not-allowed;
      box-shadow: none;
      border-color: var(--input-border-color-disabled);
      background-color: var(--input-box-shadow-disabled);
    }
  }
  &__notification {
    display: flex;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    box-sizing: border-box;
    min-height: var(--input-notification-height);
  }
}
.input--error {
  .input__input {
    border-color: var(--input-error-border-color);

    &:hover,
    &:active,
    &:focus {
      border-color: var(--input-border-color-hover);
      box-shadow: var(--input-box-shadow-hover);
    }
  }
  .input__notification {
    color: var(--input-error-font-color);
  }
}
.input--success {
  .input__input {
    border-color: var(--input-error-border-success);

    &:hover,
    &:active,
    &:focus {
      border-color: var(--input-border-color-hover);
      box-shadow: var(--input-box-shadow-hover);
    }
  }
}
