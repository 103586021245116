@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .row-#{$infix}-row-gap-4xs {
      row-gap: var(--space-4xs);
    }
    .row-#{$infix}-row-gap-3xs {
      row-gap: var(--space-3xs);
    }
    .row-#{$infix}-row-gap-2xs {
      row-gap: var(--space-2xs);
    }
    .row-#{$infix}-row-gap-xs {
      row-gap: var(--space-xs);
    }
    .row-#{$infix}-row-gap-s {
      row-gap: var(--space-s);
    }
    .row-#{$infix}-row-gap-m {
      row-gap: var(--space-m);
    }
    .row-#{$infix}-row-gap-l {
      row-gap: var(--space-l);
    }
    .row-#{$infix}-row-gap-2l {
      row-gap: var(--space-2l);
    }
    .row-#{$infix}-row-gap-xl {
      row-gap: var(--space-xl);
    }
    .row-#{$infix}-row-gap-2xl {
      row-gap: var(--space-2xl);
    }
    .row-#{$infix}-row-gap-3xl {
      row-gap: var(--space-3xl);
    }
    .row-#{$infix}-row-gap-4xl {
      row-gap: var(--space-4xl);
    }
    .row-#{$infix}-row-gap-5xl {
      row-gap: var(--space-5xl);
    }
    .row-#{$infix}-row-gap-6xl {
      row-gap: var(--space-6xl);
    }
    .row-#{$infix}-row-gap-xxl {
      row-gap: var(--space-xxl);
    }
    .row-#{$infix}-row-gap-2xxl {
      row-gap: var(--space-2xxl);
    }
    .row-#{$infix}-row-gap-3xxl {
      row-gap: var(--space-3xxl);
    }
    .row-#{$infix}-row-gap-4xxl {
      row-gap: var(--space-4xxl);
    }
  }
}
