@import "../../assets/sass/mixins.scss";
.menu-social {
  display: flex;
  align-items: center;
  column-gap: 34px;
  padding: 0;
  margin: 0;

  list-style: none;

  @include screen(lg) {
    column-gap: 30px;
  }
  &__link {
    display: flex;

    text-decoration: none;

    .icon {
      fill: var(--icon-fill-base);

      transition: all var(--transition);
    }
    .icon-img {
      transition: all var(--transition);
    }
    &:hover .icon {
      transform: scale(1.1);
    }
    &:hover .icon-img {
      transform: scale(1.1);
    }
  }
}
