.btn--icon {
  padding: 0;
  min-height: auto;

  background: none;
  border: none;
  box-shadow: none;

  color: var(--color-primary-purple);
}
.btn--icon .icon {
  transition: all var(--transition);
  fill: var(--button-icon-fill);
}
.btn--icon:hover,
.btn--icon:active,
.btn--icon:disabled {
  color: var(--color-primary-purple-light);

  background: none;
  border: none;
}
.btn--icon:hover .icon {
  fill: var(--button-icon-fill-hover);
}
.btn--icon:active .icon {
  fill: var(--button-icon-fill-active);
}
.btn--icon:disabled .icon {
  fill: var(--button-icon-fill-disable);
}
