.connect-to-wallet-button {
  position: relative;
  z-index: 1;

  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: var(--connect-to-wallet-button-pd);
  column-gap: var(--connect-to-wallet-button-column-gap);

  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  line-height: var(--button-line-height);
  font-weight: 400;
  color: var(--button-color);
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  word-break: keep-all;

  cursor: pointer;
  user-select: none;
  outline: none;
  background-color: var(--connect-to-wallet-button-bg-color);
  border: var(--connect-to-wallet-button-border-width) solid var(--connect-to-wallet-button-border-color);
  border-radius: var(--connect-to-wallet-button-border-radius);

  &:hover {
    background-color: var(--connect-to-wallet-button-bg-color-hover);
    border-color: var(--connect-to-wallet-button-border-color-hover);
  }
  &:active {
    background-color: var(--connect-to-wallet-button-bg-color-active);
    border-color: var(--connect-to-wallet-button-border-color-active);
  }

  &__state {
    display: flex;
    width: var(--connect-to-wallet-button-state-size);
    height: var(--connect-to-wallet-button-state-size);

    border-radius: var(--connect-to-wallet-button-state-border-radius);
    background-color: var(--color-error);
    box-shadow: var(--status-error-box-shadow);
  }
  &__state--success {
    background-color: var(--color-successful);
    box-shadow: var(--status-success-box-shadow);
  }
}
