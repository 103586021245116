@import "../../assets/sass/mixins.scss";
.passport {
  .window-passport {
    cursor: default;
    &:hover {
      border: var(--window-passport-border-width) solid var(--window-passport-border-color);
    }
  }
  &__body {
    width: 100%;
    max-width: var(--window-passport-width);
    margin: 0 auto;
  }
  &__link-back:not(:first-child) {
    margin-top: var(--space-s);

    @include screen(xl) {
      margin-top: var(--space-m1);
    }
    .text {
      color: var(--color-primary-purple);
      transition: all var(--transition) ease 0s;
    }
    .icon {
      fill: var(--color-primary-purple);
      transition: all var(--transition) ease 0s;
    }
    &:hover {
      .text {
        color: var(--color-primary-purple-light);
      }

      .icon {
        fill: var(--color-primary-purple-light);
      }
    }
  }
}
