@import "../../../assets/sass/mixins";
.tooltip-content {
  position: relative;

  &__body {
    position: relative;
    z-index: 5;

    padding: var(--tooltip-content-pd);
    box-sizing: border-box;

    background-color: var(--tooltip-content-bg-color);
    border: var(--tooltip-content-border-width) solid var(--tooltip-content-border-color);
    border-radius: var(--tooltip-content-border-radius);
  }
  &__visual {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 3;

    display: block;
    width: var(--tooltip-content-visual-size);
    height: var(--tooltip-content-visual-size);

    content: "";
    background-color: var(--tooltip-content-visual-bg-color);
  }
  &--top {
    .tooltip-content__visual {
      top: auto;
      bottom: 0;

      transform: translate(-50%, 50%) rotate(45deg);
    }
  }
  &--right {
    .tooltip-content__visual {
      top: 50%;
      left: 0;

      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
  &--left {
    .tooltip-content__visual {
      top: 50%;
      left: auto;
      right: 0;

      transform: translate(50%, -50%) rotate(45deg);
    }
  }
  &--bottom {
    .tooltip-content__visual {
      top: 0;

      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
  &--size-auto {
    width: auto;
  }
  &--size-base {
    width: var(--tooltip-content-width-base);

    @include screen(md) {
      width: var(--tooltip-content-width-base-md);
    }
  }
}
