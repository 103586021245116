@import "../../../assets/sass/mixins.scss";
.modal-acept {
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  @include screen(mobile) {
    row-gap: 20px;
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    @include screen(mobile) {
      flex-direction: column;
    }

    & > .btn {
      flex: 1 1 auto;
    }
  }
}
