@import "../../assets/sass/mixins.scss";

.window-passport {
  padding: var(--window-passport-padding);
  flex: 1 1 auto;

  border-radius: var(--window-passport-border-radius);
  background-color: var(--window-passport-background);
  border: var(--window-passport-border-width) solid var(--window-passport-border-color);

  transition: all var(--transition) ease;

  @include screen(xl) {
    padding: var(--window-passport-padding-xl);
    background-color: var(--window-passport-bg-color-hover);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--space-1xs);
    margin-bottom: var(--space-2s);
  }
  &__title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: var(--space-2xs);
    column-gap: var(--space-2xs);

    @include screen(xl) {
      column-gap: var(--space-xs);
    }
    .icon {
      width: 22px;
      height: 22px;

      @include screen(md) {
        width: 26px;
        height: 26px;
      }
      @include screen(xl) {
        width: 32px;
        height: 32px;
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-2s);
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__buttons {
    display: flex;
    column-gap: var(--space-2s);
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-2s);
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .btn--icon {
      column-gap: var(--space-4xs);
    }
  }
  &__item > .btn {
    @include screen(mobile) {
      font-size: var(--font-size-16);
      line-height: var(--font-line-height-s);
    }
  }
  &__item > .icon-text {
    width: 100%;
    max-width: 150px;
    justify-content: flex-end;

    @include screen(md) {
      max-width: 300px;
    }

    & .text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &__icon {
    display: flex;

    .icon,
    .icon-img {
      width: 18px;
      height: 18px;

      @include screen(md) {
        width: 22px;
        height: 22px;
      }
      @include screen(xl) {
        width: 24px;
        height: 24px;
      }
    }
  }
  &:hover {
    border: var(--border-width-m) solid var(--window-passport-border-color-hover);
  }
}
