@import "../../assets/sass/mixins.scss";
.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  background-image: url(../../assets/images/img-page-main-bg-mobile.jpg);
  background-position: center top;
  background-size: 100%;
  background-repeat: no-repeat;

  @include screen(md) {
    background-image: url(../../assets/images/img-page-main-bg-tablet.jpg);
  }
  @include screen(xl) {
    background-image: url(../../assets/images/img-page-main-bg-desctop.jpg);
  }
}
