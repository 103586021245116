@import "../../assets/sass/mixins.scss";
.wallet-info {
  &__top {
    margin-bottom: var(--space-2l);
  }
  &__img {
    display: block;
    width: 100%;
    max-width: 76px;
    margin: 0 auto;

    @include screen(xl) {
      max-width: 88px;
    }
  }
  &__body {
    text-align: center;
  }
  &__bottom {
    padding-top: var(--space-2l);
  }
}
