.btn--flat {
  min-height: auto;
  padding: 0;
  // padding-bottom: 3px;
  column-gap: 10px;

  color: var(--btn-flat-color);

  background: none;
  box-shadow: none;
  border: none;
  border-radius: 0;
  // border-bottom: var(--button-border-width) solid var(--button-border-color);

  .icon {
    fill: var(--btn-flat-icon-fill);
  }

  &::before {
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;

    display: block;
    height: var(--button-border-width);
    width: 100%;

    content: "";
    background-color: var(--button-border-color);

    transition: all var(--transition) ease;
  }
  &:hover {
    color: var(--btn-flat-color-hover);

    border-color: var(--btn-flat-border-color-hover);
    background: none;
    box-shadow: none;

    &::before {
      bottom: -10px;
    }
  }
  &:active {
    color: var(--btn-flat-color-active);
    border-color: var(--btn-flat-border-color-active);
    background: none;
    box-shadow: none;
    &::before {
      bottom: -10px;
    }
  }
  &:disabled {
    color: var(--btn-flat-color-disable);
    border-color: var(--btn-flat-border-color-disable);
    background: none;
    box-shadow: none;

    &::before {
      bottom: -4px;
    }
  }
}
