@import "../../../../assets/sass/mixins.scss";
.btn--outline {
  border: var(--button-border-width) solid var(--btn-outline-border-color);
  background: none;
  box-shadow: none;

  &:hover {
    border: var(--button-border-width) solid var(--btn-outline-border-color-hover);
    background: var(--btn-outline-background-hover);
    box-shadow: var(--button-tablet-shadow);

    @include screen(xl) {
      box-shadow: var(--button-desktop-shadow);
    }
  }
  &:active {
    border: var(--button-border-width) solid var(--btn-outline-border-color-active);
    background: var(--btn-outline-background-active);
    box-shadow: var(--button-tablet-shadow);

    @include screen(xl) {
      box-shadow: var(--button-desktop-shadow);
    }
  }
  &:disabled {
    border: var(--button-border-width) solid var(--btn-outline-border-color-disable);
    background: none;
    box-shadow: var(--button-tablet-shadow-disable);

    @include screen(xl) {
      box-shadow: var(--button-desktop-shadow-disable);
    }
  }
}
