@import "../../assets/sass/mixins.scss";
.header {
  display: flex;
  align-items: center;
  height: var(--header-height);
  box-sizing: border-box;

  @include screen(md) {
    height: var(--header-height-md);
  }

  @include screen(xl) {
    height: var(--header-height-xl);
  }
}
