.icon-img {
  display: inline-flex;

  &--size-base {
    width: var(--icon-img-size-base);
    height: var(--icon-img-size-base);
  }
  &--size-s {
    width: var(--icon-img-size-s);
    height: var(--icon-img-size-s);
  }
  &--size-1s {
    width: var(--icon-img-size-1s);
    height: var(--icon-img-size-1s);
  }
  &--size-2s {
    width: var(--icon-img-size-2s);
    height: var(--icon-img-size-2s);
  }
  &--size-m {
    width: var(--icon-img-size-m);
    height: var(--icon-img-size-m);
  }
}
