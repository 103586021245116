@import "../../assets/sass/mixins.scss";
.window-page {
  position: relative;
  width: 100%;
  max-width: var(--window-page-width-base);
  margin: 0 auto;
  padding: var(--window-page-padding-base);
  box-sizing: border-box;

  background-color: var(--window-page-bg-base);
  border: var(--window-page-border-width) solid var(--window-page-border-color);
  border-radius: var(--window-page-border-radius-base);

  @include screen(md) {
    padding: var(--window-page-padding-base-md);
  }
  @include screen(xl) {
    max-width: var(--window-page-width-base-xl);
  }

  &--padding-lg {
    padding: var(--window-page-padding-lg);

    @include screen(mobile) {
      padding: var(--window-page-padding-lg-mob);
    }
  }
}
