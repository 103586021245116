@import "../../../assets/sass/mixins.scss";
.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;

  width: 100%;
  box-sizing: border-box;
  max-width: calc(var(--modal-wrapper-width-base) + var(--modal-wrapper-padding-base));
  padding: var(--modal-wrapper-padding-base);

  transform: translate(-50%, -50%);
  background: none;
  border: none;
  outline: none;

  @include screen(xl) {
    max-width: calc(var(--modal-wrapper-width-base-xl) + var(--modal-wrapper-padding-base));
  }

  &__content {
    position: relative;

    box-sizing: border-box;
    width: 100%;
    padding: var(--modal-content-padding-base);

    background-color: var(--modal-content-bg-color-base);
    border: var(--modal-content-border-width-base) solid var(--modal-content-border-color-base);
    border-radius: var(--modal-content-border-radius-base);

    @include screen(xl) {
      padding: var(--modal-content-padding-base-xl);
    }
  }
  &__header {
    position: relative;
    margin-bottom: var(--space-m);
    column-gap: var(--space-3xs);
  }
  &__header-title {
    padding-right: var(--icon-size-4md);
  }
  &__header-close {
    position: absolute;
    top: 0;
    right: 0;

    .btn--icon .icon {
      width: 28px;
      height: 28px;

      fill: var(--color-white);

      @include screen(xl) {
        width: 38px;
        height: 38px;
      }
    }
    .btn--icon:hover .icon {
      fill: var(--color-primary-purple);
    }
  }
  &--center {
    .modal-wrapper__header {
      text-align: center;
      justify-content: center;
    }
    .modal-wrapper__body {
      text-align: center;
    }
    .modal-wrapper__header-title {
      padding-right: 0;
    }
  }
}
.modal-wrapper--size {
  &-sm {
    max-width: var(--modal-wrapper-width-sm);
  }
}
.modal-wrapper--no-close {
  .modal-wrapper__header {
    padding-right: 0;
  }
  .modal-wrapper__header-close {
    display: none;
  }
}
.modal-body {
  overflow: hidden;
}
