.link {
  display: inline-flex;
  column-gap: var(--link-column-gap);
  align-items: center;

  text-decoration: none;
  color: var(--link-color);

  transition: all var(--transition) ease 0s;

  .icon {
    fill: var(--link-color);

    transition: all var(--transition) ease 0s;
  }
  &:hover {
    color: var(--link-color-hover);

    .icon {
      fill: var(--link-color-hover);
    }
  }
}
