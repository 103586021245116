@import "../../../assets/sass/mixins.scss";
.btn {
  position: relative;
  z-index: 1;

  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  column-gap: var(--button-column-gap);
  justify-content: center;
  min-height: var(--button-height);
  padding: var(--button-padding);
  margin: 0;

  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  line-height: var(--button-line-height);
  font-weight: 500;
  color: var(--button-color);
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  word-break: keep-all;

  cursor: pointer;
  user-select: none;
  background: var(--button-background);
  border: var(--button-border-width) solid var(--button-border-color);
  border-radius: var(--button-border-radius);
  outline: none;
  box-shadow: var(--button-tablet-shadow);

  transition: all var(--transition) ease;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  & .icon {
    fill: var(--button-icon-fill-white);
    transition: all var(--transition) ease;
  }
  &:hover {
    background: var(--button-background-color-hover);
    border-color: var(--button-border-color-hover);
  }
  &:active {
    background: var(--button-background-color-active);
    border-color: var(--button-border-color-active);
  }
  &:disabled {
    cursor: not-allowed;
    background: var(--button-background-color-disabled);
    border-color: var(--button-border-color-disabled);
    box-shadow: var(--button-tablet-box-shadow-disabled);

    @include screen(xl) {
      box-shadow: var(--button-descktop-box-shadow-disabled);
    }
  }
  @include screen(md) {
    min-height: var(--button-height-md);
    padding: var(--button-padding-md);
  }
  @include screen(xl) {
    min-height: var(--button-height-xl);
    padding: var(--button-padding-xl);
    box-shadow: var(--button-desktop-shadow);
  }
}

@import "--size/--size.scss";
@import "--outline/--outline.scss";
@import "--icon/--icon.scss";
@import "--flat/--flat.scss";
@import "--secondary/--secondary.scss";
@import "--btn-icon/--btn-icon.scss";
