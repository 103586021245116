:root {
  /* Base */

  /* Base Color */
  --color-primary-purple: #a664e3;
  --color-primary-purple-light: #c78bff;
  --color-primary-purple-dark: #5c3482;

  /* Other color */
  --color-white: #ffffff;
  --color-gray-secendary-text: #acacac;
  --color-text-block-bg: rgba(41, 36, 54, 0.5);
  --color-text-block-stroke: #5d648a;
  --color-menu-bg: #221d2f;
  --color-hover-effect-menu: #3f3557;
  --color-opened-menu-bg: #2b243c;
  --color-bg-icon: #342848;
  --color-counter-bg: #292436;
  --color-ecosystem-bg: #3e3155;
  --color-footer-url: #a79cba;
  --color-error: #dc3535;
  --color-successful: #2ed949;

  --color-button: var(--color-primary-purple);
  --color-button-hover: #bc74ff;
  --color-button-active: var(--color-primary-purple-dark);
  --color-button-disable: #494949;
  --color-body-bg: #1b1925;

  /* Base Gradiend */
  --body-gradient: radial-gradient(
      62.8% 204.24% at 0% 1.11%,
      rgba(160, 72, 241, 0.2) 8.19%,
      rgba(143, 67, 214, 0.132051) 30.24%,
      rgba(166, 100, 227, 0) 57.47%
    ),
    #1b1925;
  --body-gradient-circle: radial-gradient(
    50% 50% at 50% 50%,
    rgba(92, 56, 125, 0.75) 0%,
    rgba(74, 56, 125, 0.484375) 41.67%,
    rgba(92, 56, 125, 0) 100%
  );

  /* Base Font */
  --font-main: "Inter";
  --font-size-body: 16px;
  --font-line-height-body: 20px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-30: 30px;
  --font-size-32: 32px;
  --font-size-36: 36px;
  --font-size-38: 38px;
  --font-size-40: 40px;
  --font-size-48: 48px;
  --font-size-50: 50px;
  --font-size-64: 64px;
  --font-size-72: 72px;

  --font-line-height-16: 16px;
  --font-line-height-18: 18px;
  --font-line-height-20: 20px;
  --font-line-height-22: 22px;
  --font-line-height-24: 24px;
  --font-line-height-26: 26px;
  --font-line-height-28: 28px;
  --font-line-height-30: 30px;
  --font-line-height-32: 32px;
  --font-line-height-34: 34px;
  --font-line-height-38: 38px;
  --font-line-height-42: 42px;
  --font-line-height-44: 44px;
  --font-line-height-48: 48px;
  --font-line-height-58: 58px;
  --font-line-height-60: 60px;
  --font-line-height-62: 62px;
  --font-line-height-82: 82px;
  --font-line-height-86: 82px;

  /* Base Container */
  --container-block-sm: 470px;
  --container-block-md: 510px;

  /* Base Grid */
  --container-width-media-sm: 540px;
  --container-width-media-md: 774px;
  --container-width-media-lg: 960px;
  --container-width-media-xl: 1180px;
  --container-width: 1248px;

  --bases-gutter-mob-x: 20px;
  --bases-gutter-x: 16px;

  --grid-breakpoints-xs: 0;
  --grid-breakpoints-sm: 576px;
  --grid-breakpoints-md: 768px;
  --grid-breakpoints-lg: 992px;
  --grid-breakpoints-xl: 1200px;
  --grid-breakpoints-xxl: 1400px;

  --space-4xs: 2px;
  --space-3xs: 4px;
  --space-2xs: 8px;
  --space-1xs: 10px;
  --space-xs: 12px;
  --space-2s: 14px;
  --space-1s: 15px;
  --space-s: 16px;
  --space-m1: 18px;
  --space-m: 20px;
  --space-l: 24px;
  --space-1l: 22px;
  --space-2l: 28px;
  --space-xl: 34px;
  --space-2xl: 40px;
  --space-3xl: 54px;
  --space-4xl: 64px;
  --space-5xl: 72px;
  --space-6xl: 82px;
  --space-xxl: 108px;
  --space-2xxl: 120px;
  --space-3xxl: 160px;
  --space-4xxl: 180px;

  /* Base Background */
  --background-base: var(--color-primary-purple);

  /* Base Border */
  --border-radius-base: 16px;
  --border-radius-es: 3px;
  --border-radius-s: 6px;
  --border-radius-m: 7px;
  --border-radius-2m: 10px;
  --border-radius-l: 21px;

  --border-width-s: 1px;
  --border-width-m: 2px;

  --border-color-base: var(--color-primary-purple);

  /* Base Effect Styles */
  --button-desktop-shadow: 0px 0px 50px 1px rgba(166, 100, 227, 0.5);
  --button-desktop-shadow-disable: 0px 0px 50px 1px rgba(137, 125, 149, 0.5);

  --button-tablet-shadow: 0px 0px 30px rgba(166, 100, 227, 0.5);
  --button-tablet-shadow-disable: 0px 0px 30px rgba(137, 125, 149, 0.5);

  --icon-shadow: 0px 0px 52px 3px rgba(166, 100, 227, 0.35);
  --point-shadow: 0px 0px 6px 2px rgba(166, 100, 227, 0.58);

  --background: blur(2px);

  --roadmap-line-shadow: 0px 0px 4px rgba(166, 100, 227, 0.75);
  --roadmap-point-shadow: 0px 0px 16px rgba(199, 139, 255, 0.55);

  --forms-shadow-active-mobile: 0px 0px 14px rgba(133, 94, 168, 0.25);
  --forms-shadow-active-tablet: 0px 0px 22px rgba(133, 94, 168, 0.4);
  --forms-shadow-active-desctop: 0px 0px 30px rgba(133, 94, 168, 0.4);

  --status-error-box-shadow: 0px 0px 8px 1px rgba(220, 53, 53, 0.75);
  --status-success-box-shadow: 0px 0px 8px 1px rgba(46, 217, 73, 0.75);

  /* Base Icon */
  --icon-size-base: 30px;

  --icon-size-sm: 12px;
  --icon-size-2sm: 14px;
  --icon-size-3sm: 16px;
  --icon-size-md: 20px;
  --icon-size-4md: 28px;
  --icon-size-lg: 32px;
  --icon-size-xl: 40px;

  /* Base Icon Fill*/
  --icon-fill-base: var(--color-white);
  --icon-fill-primary: var(--color-primary-purple);
  --icon-fill-bg-icon: var(--color-bg-icon);

  /* Base Icon Img */
  --icon-img-size-base: 30px;
  --icon-img-size-s: 20px;
  --icon-img-size-1s: 22px;
  --icon-img-size-2s: 24px;
  --icon-img-size-m: 32px;

  /* Base Link */
  --link-color: var(--color-primary-purple-light);
  --link-color-hover: var(--color-primary-purple);
  --link-column-gap: 8px;

  /* Base Transition */
  --transition: 0.4s;

  /* Components */

  /* Button */
  --button-height: 52px;
  --button-height-md: 56px;
  --button-height-xl: 64px;
  --button-padding: 10px 28px;
  --button-padding-md: 14px 38px;
  --button-padding-xl: 16px 56px;
  --button-column-gap: 10px;

  --button-font-family: var(--font-main);
  --button-font-size: var(--font-size-14);
  --button-line-height: var(--font-line-height-18);
  --button-color: var(--color-white);
  --button-tablet-box-shadow: var(--button-tablet-shadow);
  --button-desktop-box-shadow: var(--button-desktop-shadow);
  --button-background: var(--color-primary-purple);
  --button-border-width: var(--border-width-s);
  --button-border-color: var(--border-color-base);
  --button-border-radius: 3px;

  --button-border-color-hover: var(--color-button-hover);
  --button-background-color-hover: var(--color-button-hover);

  --button-border-color-active: var(--color-button-active);
  --button-background-color-active: var(--color-button-active);

  --button-border-color-disabled: var(--color-button-disable);
  --button-background-color-disabled: var(--color-button-disable);
  --button-tablet-box-shadow-disabled: var(--button-tablet-shadow-disable);
  --button-descktop-box-shadow-disabled: var(--button-desktop-shadow-disable);

  --button-btn-icon-height: 52px;
  --button-btn-icon-padding: 10px 22px;
  --button-btn-icon-md-height: 57px;
  --button-btn-icon-md-padding: 12px 38px;
  --button-btn-icon-lg-height: 65px;
  --button-btn-icon-lg-padding: 16px 56px;

  --btn-flat-color: var(--color-button);
  --btn-flat-icon-fill: var(--color-primary-purple);
  --btn-flat-color-hover: var(--color-button-hover);
  --btn-flat-color-active: var(--color-button-active);
  --btn-flat-color-disable: var(--color-button-disable);

  --btn-flat-border-color-hover: var(--color-button-hover);
  --btn-flat-border-color-active: var(--color-button-active);
  --btn-flat-border-color-disable: var(--color-button-disable);

  --btn-outline-border-color: var(--color-button);
  --btn-outline-filter: var(--button-tablet-shadow);
  --btn-outline-border-color-hover: var(--color-button-hover);
  --btn-outline-background-hover: var(--color-button-hover);
  --btn-outline-border-color-active: var(--color-button-active);
  --btn-outline-background-active: var(--color-button-active);
  --btn-outline-border-color-disable: var(--color-button-disable);

  --button-icon-fill: var(--color-primary-purple);
  --button-icon-fill-white: var(--color-white);
  --button-icon-fill-hover: var(--color-primary-purple-light);
  --button-icon-fill-active: var(--color-button-active);
  --button-icon-fill-disable: var(--color-button-disable);

  /* Connect To Wallet Button */
  --connect-to-wallet-button-pd: 8px 18px;
  --connect-to-wallet-button-column-gap: 12px;
  --connect-to-wallet-button-bg-color: var(--color-menu-bg);
  --connect-to-wallet-button-border-width: 1px;
  --connect-to-wallet-button-border-color: var(--color-text-block-stroke);
  --connect-to-wallet-button-border-radius: 31px;

  --connect-to-wallet-button-bg-color-hover: var(--color-primary-purple);
  --connect-to-wallet-button-border-color-hover: var(--color-primary-purple);

  --connect-to-wallet-button-bg-color-active: var(--color-hover-effect-menu);
  --connect-to-wallet-button-border-color-active: var(--color-hover-effect-menu);

  --connect-to-wallet-button-state-size: 14px;
  --connect-to-wallet-button-state-border-radius: 50%;

  /* Header*/
  --header-height: 84px;
  --header-height-md: 115px;
  --header-height-xl: 151px;

  /* Input */
  --input-label-height: 26px;
  --input-label-column-gap: 4px;

  --input-input-height: 42px;

  --input-font-family: var(--font-main);
  --input-font-size: 14px;
  --input-line-height: 18px;
  --input-font-color: var(--color-white);

  --input-input-height-xl: 44px;
  --input-font-size-xl: 16px;
  --input-line-height-xl: 20px;

  --input-border-width: 1px;
  --input-border-color: var(--color-text-block-stroke);
  --input-border-radius: 4px;
  --input-bg-color: var(--color-menu-bg);

  --input-placeholder-color: var(--color-footer-url);

  --input-notification-height: 26px;

  --input-border-color-hover: var(--color-primary-purple);
  --input-box-shadow-hover: var(--forms-shadow-active-mobile);

  --input-box-shadow-hover-md: var(--forms-shadow-active-tablet);
  --input-box-shadow-hover-xl: var(--forms-shadow-active-desctop);

  --input-color-disabled: var(--color-gray-secendary-text);
  --input-border-color-disabled: var(--color-button-disable);
  --input-box-shadow-disabled: var(--color-button-disable);

  --input-error-font-color: var(--color-error);
  --input-error-border-color: var(--color-error);

  --input-error-border-success: var(--color-successful);

  /* Link */
  --link-color: var(--color-primary-purple-light);
  --link-color-hover: var(--color-primary-purple);
  --link-column-gap: 8px;

  /* Logo */
  --logo-width: 207px;
  --logo-width-mob: 34px;

  /* Modal */
  --modal-wrapper-width-base: 390px;
  --modal-wrapper-width-base-xl: 484px;
  --modal-wrapper-width-sm: 300px;
  --modal-wrapper-padding-base: 20px;

  --modal-content-bg-color-base: var(--color-opened-menu-bg);
  --modal-content-border-color-base: var(--color-text-block-stroke);
  --modal-content-border-width-base: 1px;
  --modal-content-border-radius-base: var(--border-radius-s);
  --modal-content-padding-base: 18px 24px;
  --modal-content-padding-base-xl: 24px;

  /* Select Custom */
  --select-custom-column-gap: var(--space-3s);
  --select-custom-text-color: var(--color-footer-url);
  --select-custom-font-size: 14px;
  --select-custom-line-height: 18px;

  --select-custom-font-size-xl: 16px;
  --select-custom-line-height-xl: 20px;

  --select-custom-border-radius: 4px;
  --select-custom-border-color: var(--color-text-block-stroke);
  --select-custom-border-width: 1px;
  --select-custom-bg-color: var(--color-menu-bg);
  --select-custom-box-shadow: var(--forms-shadow-active-mobile);
  --select-custom-border-color-active: var(--color-primary-purple);

  --select-custom-box-shadow-md: var(--forms-shadow-active-tablet);
  --select-custom-box-shadow-xl: var(--forms-shadow-active-desctop);

  --select-custom-control-pd: 0 14px;
  --select-custom-control-height: 42px;
  --select-custom-control-min-width: 88px;

  --select-custom-control-height-xl: 44px;

  --select-custom-indicator-pd: 0 0 0 var(--space-xs);

  --select-custom-menu-list-min-height: 50px;
  --select-custom-menu-list-max-height: 450px;
  --select-custom-menu-bg-color: var(--color-menu-bg);
  --select-custom-menu-border-color: var(--color-text-block-stroke);

  --select-custom-option-pd: 9px 13px;
  --select-custom-option-bg-color-hover: var(--color-hover-effect-menu);

  /* Tooltips */
  --tooltips-icon-fill: var(--color-primary-purple);
  --tooltips-icon-fill-hover: var(--color-primary-purple-light);
  --tooltips-content-position: 45px;

  /* Tooltip Content */
  --tooltip-content-width-base: 200px;
  --tooltip-content-width-base-md: 304px;
  --tooltip-content-pd: 12px 14px;
  --tooltip-content-bg-color: var(--color-menu-bg);
  --tooltip-content-border-color: var(--color-text-block-stroke);
  --tooltip-content-border-width: 1px;
  --tooltip-content-border-radius: 2px;

  --tooltip-content-visual-size: 16px;
  --tooltip-content-visual-bg-color: var(--color-text-block-stroke);

  /* Verification Status */
  --verification-status-succes: var(--color-success);
  --verification-status-warning: var(--color-warning);
  --verification-status-error: var(--color-error);

  /* Window Page  */
  --window-page-width-base: 482px;
  --window-page-width-base-xl: 594px;

  --window-page-padding-base: 22px 24px;
  --window-page-padding-base-md: 24px;

  --window-page-border-width: 1px;
  --window-page-border-color: var(--color-text-block-stroke);
  --window-page-bg-base: var(--color-text-block-bg);
  --window-page-border-radius-base: var(--border-radius-s);

  --window-page-padding-lg: 42px 32px 32px 32px;
  --window-page-padding-lg-mob: 30px 20px 20px 20px;

  /* Window Passport  */
  --window-passport-padding: 24px 22px;
  --window-passport-padding-xl: 24px;
  --window-passport-background: var(--color-text-block-bg);
  --window-passport-border-radius: 6px;
  --window-passport-border-width: var(--border-width-m);
  --window-passport-border-color: var(--color-text-block-stroke);

  --window-passport-border-color-hover: var(--color-button-hover);
  --window-passport-bg-color-hover: var(--color-counter-bg);
}
