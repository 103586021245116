$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin screen($size) {
  $xxl: "(min-width: 1400px)";
  $xl: "(min-width: 1200px)";
  $lg: "(min-width: 992px)";
  $md: "(min-width: 768px)";
  $sm: "(min-width: 576px)";
  $mobile: "(max-width: 767px)";
  $mobileMin: "(min-width: 375px)";

  @if $size == lg {
    @media only screen and #{$lg} {
      @content;
    }
  } @else if $size == md {
    @media only screen and #{$md} {
      @content;
    }
  } @else if $size == sm {
    @media only screen and #{$sm} {
      @content;
    }
  } @else if $size == mobile {
    @media only screen and #{$mobile} {
      @content;
    }
  } @else if $size == mobileMin {
    @media only screen and #{$mobileMin} {
      @content;
    }
  } @else if $size == xl {
    @media only screen and #{$xl} {
      @content;
    }
  } @else if $size == xxl {
    @media only screen and #{$xxl} {
      @content;
    }
  } @else {
    @media only screen and #{$size} {
      @content;
    }
  }
}

// @include screen(mobile) {
//
// }

@mixin prefix($property, $value, $vendors: webkit moz ms o, $default: true) {
  @if $vendors {
    @each $vendor in $vendors {
      #{"-" + $vendor + "-" + $property}: #{$value};
    }
  }
  @if $default {
    #{$property}: #{$value};
  }
}

@mixin center-vertically {
  position: absolute;
  top: 50%;
  left: 50%;
  @include prefix(transform, translate(-50%, -50%), "webkit" "ms");
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
