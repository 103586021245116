@import "../../assets/sass/mixins";
.verification-status {
  display: inline-flex;

  .icon {
    width: 18px;
    height: 18px;

    @include screen(md) {
      width: 22px;
      height: 22px;
    }
    @include screen(xl) {
      width: 24px;
      height: 24px;
    }
  }
  &--error {
    .icon {
      fill: var(--color-error);
    }
  }
  &--pending {
    .icon {
      fill: var(--color-primary-purple);
    }
  }
  &--success {
    .icon {
      fill: var(--color-successful);
    }
  }
}
